var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "공용작업실",
            "tabActive": "신청방법",
            "bg": "/images/sub/sub-visual5.jpg"
          },
          scopedSlots: _vm._u([{
            key: "tit",
            fn: function () {
              return [_vm._v("공용작업실")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-secondary', {
    staticClass: "page-section--first page-section--xs",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("운영안내")];
      },
      proxy: true
    }])
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--sm"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": "row--xs"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용시간")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 평일 09:00 ~ 16:30 (주말,공휴일 휴무)"), _c('br'), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 직원 휴가 시 공용작업실 운영 휴무 (최소 1일 전 문자 안내)")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("장소")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 동대문구 답십리로38길 19 A동 1층(답십리동)"), _c('br'), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 용신동 패션봉제 복합지원센터는 공용작업실을 운영하지 않습니다.")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("문의처")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("02-2212-5586")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("이용가능 장비")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("본봉(3대), 오버록(1대), 날날이(1대), 캐드, 마네킹")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('page-section-secondary', {
    staticClass: "page-section--xs pt-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("주의사항")];
      },
      proxy: true
    }])
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--sm"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": "row--xs"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 공용작업실은 "), _c('b', [_vm._v("사전예약제")]), _vm._v("로 운영되며, "), _c('b', [_vm._v("관리자 승인 후 이용 가능")]), _vm._v("합니다. "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 예약 마감(금요일 13:00) 이후 공석에 한하여 전화예약 가능")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("1인당 "), _c('b', [_vm._v("주 최대 2회, 1일 최대 4시간")]), _vm._v(" 이용 가능합니다.")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_c('b', [_vm._v("본봉은 1인당 1대만 예약 가능")]), _vm._v("하며, 본인 외 "), _c('b', [_vm._v("대리신청은 불가")]), _vm._v("합니다.")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("공용작업실 내 "), _c('b', [_vm._v("음식물 반입과 개인 물품 보관은 금지")]), _vm._v("됩니다.")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("퇴실 전 "), _c('b', [_vm._v("본인이 이용한 자리를 정리")]), _vm._v("해주시고, "), _c('b', [_vm._v("다리미, 냉․난방기, 전등의 전원")]), _vm._v("을 꺼주시기 바랍니다.")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("개인의 부주의로 인한 사고, 부상, 장비파손, 물품 분실 등에 대해서는 센터가 책임지지 않으며 시설물 훼손․파손 등에 대해서는 사용자가 배상합니다.")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("·")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("주의사항을 준수하지 않을 경우 공용작업실 이용이 제한될 수 있습니다.")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('page-section-secondary', {
    staticClass: "page-section--xs pt-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("예약안내")];
      },
      proxy: true
    }])
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--sm"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": "row--xs"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("예약처")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com)")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("예약가능 요일")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 매주 월 ~ 금 ⇨ "), _c('b', [_vm._v("차주 월 ~ 금")]), _vm._v(" 예약 가능 "), _c('div', {
          staticClass: "mt-10px"
        }, [_c('v-img', {
          attrs: {
            "max-width": "448",
            "aspect-ratio": 448 / 460,
            "src": "/images/sub/studio/studio-application-img.jpg"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "txtItem": "",
      "itemSize": "row--sm",
      "titCols": "auto",
      "txtCols": "",
      "titWidth": "min-width:98px;",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("예약 승인")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 매주 "), _c('b', [_vm._v("금요일 13:00")]), _vm._v("이후 일괄 승인 "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v(" ※ 동일 시간, 동일 장비 예약 건에 대하여 "), _c('b', [_vm._v("선착순")]), _vm._v("으로 승인 처리"), _c('br'), _vm._v(" ※ 예약 마감(금요일 13:00) 이후 신청 건은 미승인 처리(전화예약 가능) ")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('page-section-secondary', {
    staticClass: "page-section--last page-section--xs pt-0",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("예약방법")];
      },
      proxy: true
    }])
  }, [_c('card-primary', {
    staticClass: "border-4",
    attrs: {
      "cardInnerSize": "card-inner--md"
    }
  }, [_c('u-txt-items', {
    attrs: {
      "itemsSize": "row--xs"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "1072",
      "aspect-ratio": 1072 / 916,
      "src": "/images/sub/studio/studio-application-img2.jpg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("1.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 동대문구 패션봉제지원센터 홈페이지(ddmsfcenter.com) > 공용작업실 > 신청하기 접속 "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 회원가입 및 로그인 불필요")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("2.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("신청자명 : 예약자 이름 입력")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("3.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("연락처 : 연락가능한 휴대전화 번호 입력")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("4.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 날짜 선택 : 차주 월~금 예약 가능 "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 차주 월~금 외의 날짜 예약시 미승인 처리")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("5.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 시간 선택 : 오전(09:00~13:00), 오후(13:00~16:30) "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v("※ 4시간 단위로만 예약가능")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("6.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 장비 선택 : 본봉/날날이/캐드/오버록/마네킹 중 택1 "), _c('div', {
          staticClass: "primary--text"
        }, [_vm._v(" ※ 본봉은 1인당 1대만 예약 가능"), _c('br'), _vm._v(" ※ 본봉 예약 시 오버록 예약 없이 사용 가능 ")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('u-txt-items', {
    staticClass: "ma-n2px",
    attrs: {
      "txtItem": "",
      "titCols": "auto",
      "txtCols": "",
      "colClass": "pa-2px",
      "titWidth": "",
      "titClass": "txt--lg line-height-15",
      "txtClass": "txt--lg line-height-15"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("7.")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("신청 사유 : 신청사유 및 작성")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }